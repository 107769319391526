const initialState = {
  hasIdentificated: false,
};

const UserReducer = (state = initialState, action) => {
  if (action.type === 'IDENTIFICATION')
    return {
      hasIdentificated: action.data.hasIdentificated,
    };
  return { ...state };
};

export default UserReducer;
