import store from 'storejs';

//초기 상태값 설정
const initialState = {
  accessToken: store.get('accessToken') ?? '',
};

//리듀서 설정
const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN': {
      return {
        ...state,
        accessToken: action.data.accessToken,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        accessToken: '',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default LoginReducer;
