import { combineReducers } from 'redux';
import HomeReducer from './home';
import LoginReducer from './login';
import SelectDateReducer from './date';
import ReportDateReducer from './reportDate';
import { AssignmentReducer } from './assignment';
import { AlarmCntReducer } from './alarmCnt';
import UserReducer from './user';

const RootReducer = combineReducers({
  HomeReducer,
  LoginReducer,
  SelectDateReducer,
  AssignmentReducer,
  UserReducer,
  ReportDateReducer,
  AlarmCntReducer,
});

export default RootReducer;
