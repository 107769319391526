import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Loader from './components/common/loader';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));
const SignUp = React.lazy(() => import('./pages/signup/SignUp'));
const SignUpSuccess = React.lazy(() => import('./pages/signup/Success'));

//컴포넌트
const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/signup" name="SignUp Page" element={<SignUp />} />
          <Route exact path="/signup/success" element={<SignUpSuccess />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route exact path="/*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
