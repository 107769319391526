import { ALARM_CNT } from '../actions/alarmCnt';

const initailState = {
  alarmCnt: 0,
};

export const AlarmCntReducer = (state = initailState, action) => {
  switch (action.type) {
    case ALARM_CNT:
      return {
        ...state,
        alarmCnt: action.alarmCnt,
      };
    default:
      return state;
  }
};
