export const ASSIGNMENT_TITLE = 'assignment/ASSIGNMENT_TITLE';
export const ASSIGNMENT_CONTENT = 'assignment/ASSIGNMENT_CONTENT';
export const ASSIGNMENT_PROVIDE_WAY = 'assignment/ASSIGNMENT_PROVIDE_WAY';
export const ASSIGNMENT_TYPE = 'assignment/ASSIGNMENT_TYPE';
export const ASSIGNMENT_GRADE = 'assignment/ASSIGNMENT_GRADE';
export const ASSIGNMENT_CLASSNUMBER = 'assignment/ASSIGNMENT_CLASSNUMBER';
export const ASSIGNMENT_STUDENTS_ID = 'assignment/ASSIGNMENT_STUDENTS_ID';
export const ASSIGNMENT_STARTAT = 'assignment/ASSIGNMENT_STARTAT';
export const ASSIGNMENT_ENDAT = 'assignment/ASSIGNMENT_ENDAT';
export const ASSIGNMENT_FINISHWAY = 'assignment/ASSIGNMENT_FINISHWAY';

export const ASSIGNMENT_ID = 'assignment/ASSIGNMENT_ID';
export const UNIT_ID = 'assignment/UNIT_ID';
export const ACHIEVEMENT_ID = 'assignment/ACHIEVEMENT_ID';
export const CAPABILITY_ID = 'assignment/CAPABILITY_ID';
export const PROBLEMWAY_ID = 'assignment/PROBLEMWAY_ID';
export const PROBLEMTYPE_ID = 'assignment/PROBLEMTYPE_ID';
export const PROBLEMLEVEL_ID = 'assignment/PROBLEMLEVEL_ID';
export const PROBLEM_CNT = 'assignment/PROBLEM_CNT';

export const ASSIGNMENTS = 'assignment/ASSIGNMENTS';

export const assignmentTitle = assignmentTitle => ({
  type: ASSIGNMENT_TITLE,
  assignmentTitle,
});
export const assignmentContent = assignmentContent => ({
  type: ASSIGNMENT_CONTENT,
  assignmentContent,
});
export const assignmentProvideWay = assignmentProvideWay => ({
  type: ASSIGNMENT_PROVIDE_WAY,
  assignmentProvideWay,
});
export const assignmentType = assignmentType => ({
  type: ASSIGNMENT_TYPE,
  assignmentType,
});
export const assignmentGrade = assignmentGrade => ({
  type: ASSIGNMENT_GRADE,
  assignmentGrade,
});
export const assignmentClassNumber = assignmentClassNumber => ({
  type: ASSIGNMENT_CLASSNUMBER,
  assignmentClassNumber,
});
export const assignmentStudentsId = assignmentStudentsId => ({
  type: ASSIGNMENT_STUDENTS_ID,
  assignmentStudentsId,
});
export const assignmentStartAt = assignmentStartAt => ({
  type: ASSIGNMENT_STARTAT,
  assignmentStartAt,
});
export const assignmentEndAt = assignmentEndAt => ({
  type: ASSIGNMENT_ENDAT,
  assignmentEndAt,
});
export const assignmentFinishWay = assignmentFinishWay => ({
  type: ASSIGNMENT_FINISHWAY,
  assignmentFinishWay,
});
export const assignmentId = assignmentId => ({
  type: ASSIGNMENT_ID,
  assignmentId,
});
export const unitId = unitId => ({
  type: UNIT_ID,
  unitId,
});

export const achievementId = achievementId => ({
  type: ACHIEVEMENT_ID,
  achievementId,
});

export const capabilityId = capabilityId => ({
  type: CAPABILITY_ID,
  capabilityId,
});

export const problemWayId = problemWayId => ({
  type: PROBLEMWAY_ID,
  problemWayId,
});

export const problemTypeId = problemTypeId => ({
  type: PROBLEMTYPE_ID,
  problemTypeId,
});

export const problemLevelId = problemLevelId => ({
  type: PROBLEMLEVEL_ID,
  problemLevelId,
});

export const problemCnt = problemCnt => ({
  type: PROBLEM_CNT,
  problemCnt,
});
export const assignments = assignments => ({
  type: ASSIGNMENTS,
  assignments,
});
