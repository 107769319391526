import { css } from 'styled-components';

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const color = {
  //aquaMarine 컬러가 제플린 2번째 컬러인데 고정적으로 버튼에서 쓰이는 컬러입니다.
  //그래서 따로 sub02 로 쓰지 않고 aquaMarine 으로 이름짓고, sub02 는 생략했으니 참고 부탁드려요!
  Main: '#0B406E',
  hoverMain: '#0b406e',
  aquaMarine: '#2dd4ce',
  hoverAquaMarine: '#00b9b9',
  sub01: '#5fe3d9',
  sub03: '5eeddd',
  sub04: '#c3f2f0',
  sub05: '#e5f7f6',
  buttonDefault: '#0b4a80',
  buttonGray: '#e3e3e3',
  hoverButtonGray: '#b2b2b2',
  cancelGray: '#f2f2f2',
  hoverCancelGray: '#ebebeb',
  disableGray: '#ced4da',
  backgroundGray: '#fafafa',
  black01: '#171725',
  black02: '#000000',
  white: '#ffffff',
  dusk: '#5a607f',
  grayblue: '#353a55',
  brownGray: '#979797',
  dark: '#131523',
};

export const titleStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
  font-size: 2.8rem;
  font-weight: 600;
  color: ${color.black01};
`;

export const boxStyle = css`
  background: ${color.white};
  box-shadow: 4px 5px 12px 0 rgba(21, 34, 50, 0.08);
  border-radius: 0.8rem;
  overflow: hidden;
`;
