const initialState = {
  startDate: '',
  endDate: '',
};

const ReportDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REPORT_START_DATE': {
      return {
        ...state,
        startDate: action.payload,
      };
    }
    case 'REPORT_END_DATE': {
      return {
        ...state,
        endDate: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default ReportDateReducer;
