const initialState = {
  startDate: '',
  endDate: '',
};

const SelectDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STARTDATE': {
      return {
        ...state,
        startDate: action.data,
      };
    }
    case 'ENDDATE': {
      return {
        ...state,
        endDate: action.data,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default SelectDateReducer;
