import {
  ACHIEVEMENT_ID,
  ASSIGNMENTS,
  ASSIGNMENT_CLASSNUMBER,
  ASSIGNMENT_CONTENT,
  ASSIGNMENT_ENDAT,
  ASSIGNMENT_FINISHWAY,
  ASSIGNMENT_GRADE,
  ASSIGNMENT_ID,
  ASSIGNMENT_PROVIDE_WAY,
  ASSIGNMENT_STARTAT,
  ASSIGNMENT_STUDENTS_ID,
  ASSIGNMENT_TITLE,
  ASSIGNMENT_TYPE,
  CAPABILITY_ID,
  PROBLEMLEVEL_ID,
  PROBLEMTYPE_ID,
  PROBLEMWAY_ID,
  PROBLEM_CNT,
  UNIT_ID,
} from '../actions/assignment';

const initialState = {
  assignmentTitle: '',
  assignmentContent: '',
  assignmentProvideWay: '',
  assignmentType: '',
  assignmentGrade: '',
  assignmentClassNumber: '',
  assignmentStudentsId: '',
  assignmentStartAt: '',
  assignmentEndAt: '',
  assignmentFinishWay: '',
  assignmentId: 'ai',
  unitId: '',
  achievementId: '',
  capabilityId: '',
  problemWayId: '',
  problemTypeId: '',
  problemLevelId: '',
  problemCnt: 0,
  assignments: [],
};

export const AssignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGNMENT_TITLE:
      return {
        ...state,
        assignmentTitle: action.assignmentTitle,
      };
    case ASSIGNMENT_CONTENT:
      return {
        ...state,
        assignmentContent: action.assignmentContent,
      };
    case ASSIGNMENT_PROVIDE_WAY:
      return {
        ...state,
        assignmentProvideWay: action.assignmentProvideWay,
      };
    case ASSIGNMENT_TYPE:
      return {
        ...state,
        assignmentType: action.assignmentType,
      };
    case ASSIGNMENT_GRADE:
      return {
        ...state,
        assignmentGrade: action.assignmentGrade,
      };
    case ASSIGNMENT_CLASSNUMBER:
      return {
        ...state,
        assignmentClassNumber: action.assignmentClassNumber,
      };
    case ASSIGNMENT_STUDENTS_ID:
      return {
        ...state,
        assignmentStudentsId: action.assignmentStudentsId,
      };
    case ASSIGNMENT_STARTAT:
      return {
        ...state,
        assignmentStartAt: action.assignmentStartAt,
      };
    case ASSIGNMENT_ENDAT:
      return {
        ...state,
        assignmentEndAt: action.assignmentEndAt,
      };
    case ASSIGNMENT_FINISHWAY:
      return {
        ...state,
        assignmentFinishWay: action.assignmentFinishWay,
      };
    case ASSIGNMENT_ID:
      return {
        ...state,
        assignmentId: action.assignmentId,
      };
    case UNIT_ID:
      return {
        ...state,
        unitId: action.unitId,
      };
    case ACHIEVEMENT_ID:
      return {
        ...state,
        achievementId: action.achievementId,
      };
    case CAPABILITY_ID:
      return {
        ...state,
        capabilityId: action.capabilityId,
      };
    case PROBLEMWAY_ID:
      return {
        ...state,
        problemWayId: action.problemWayId,
      };
    case PROBLEMTYPE_ID:
      return {
        ...state,
        problemTypeId: action.problemTypeId,
      };
    case PROBLEMLEVEL_ID:
      return {
        ...state,
        problemLevelId: action.problemLevelId,
      };
    case PROBLEM_CNT:
      return {
        ...state,
        problemCnt: action.problemCnt,
      };
    case ASSIGNMENTS:
      return {
        ...state,
        assignments: action.assignments,
      };

    default:
      return state;
  }
};
